export const las = "https://www.lasoberana.com.ve"
export const lasdev = "https://sadcsv-dev.lasoberana.com.ve"
export const fyl = "https://www.fylsolucionesintegrales.com"
export const ipsfa = "https://ipsfa.lasoberana.com.ve"
export const aiko = "https://www.aikomotors.com.ve"
export const localhost = "http://localhost:8080"
export const local = "http://localhost:"
let url1, url2
switch (location.origin) {
  case las: // LAS PRD
    url1 = `${las}/api1/`
    url2 = `${fyl}/api2/`
    break;
  case lasdev: // LAS DEV
    url1 = `${lasdev}/api1/`
    url2 = `${lasdev}/api2/`
    break;
  case fyl: // FYL PRD
    url1 = `${las}/api1/`
    url2 = `${fyl}/api2/`
    break;
  case ipsfa: // IPSFA PRD
    url1 = `${ipsfa}/api1/`
    url2 = `${fyl}/api2/`
    break;
  case aiko: // AIKO PRD
    url1 = `${las}/api1/`
    url2 = `${fyl}/api2/`
    break;
  default: // LOCAL
    url1 = `${local}3000/api1/`
    url2 = `${local}3001/api2/`
    break;
}
export const api1 = url1
export const api2 = url2
export const fylapi1 = `${fyl}/api1/`
export const fylapi2 = `${fyl}/api2/`
