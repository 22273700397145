import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import VueCookies from 'vue-cookies'
// import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { Bar } from 'vue-chartjs/legacy'

// Bootstrap
import '@/assets/scss/_main.scss'
import 'leaflet/dist/leaflet.css'
// import 'vue-select/dist/vue-select.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Title
import titleMixin from './plugins/titleMixin'
Vue.mixin(titleMixin)

// Autocomplete
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

// Vue Select
// Vue.component('v-select', vSelect)

// Another select
Vue.component('multi-select', Multiselect)

// Cookies default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '1d' })

// Force uppercase
Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  }
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Bar)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
