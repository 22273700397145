<template>
  <div id="app">
    <b-navbar v-if="isRouteNameValid" toggleable="lg" :variant="isRouteNameValid ? themes[$route.name].variant : 'info'" :type="isRouteNameValid ? themes[$route.name].type : 'light'" class="px-5">
      <b-navbar-brand to="#">
        <img :src="require(`@/assets/images/${mainImage}.png`)" alt="Logo" class="mr-4" style="height: 80px;">
        <img v-if="routeImage" :src="require(`@/assets/images/${routeImage}.png`)" alt="Logo" style="height: 80px;">
      </b-navbar-brand>
      <!--<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="$route.name == 'home'">
          <b-nav-item to="ikco">IKCO</b-nav-item>
          <b-nav-item to="saipa">SAIPA</b-nav-item>
          <b-nav-item to="veniran">VENIRAN</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-brand to="#">
            <!--<img src="@/assets/images/mision-transporte.png" alt="Logo" style="height: 80px;">-->
          </b-navbar-brand>
          <!-- <b-nav-item to="#catalogue">Catálogo</b-nav-item>
          <b-nav-item to="#form">Formulario</b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
import { ipsfa } from './db';
export default {
  title() { return "La Soberana" },
  data() {
    return {
      themes: {
        ikco: {
          variant: 'primary',
          type: 'dark'
        },
        saipa: {
          variant: 'info',
          type: 'light'
        },
        veniran: {
          variant: 'secondary',
          type: 'light'
        },
        censoautomotriz: {
          variant: 'info',
          type: 'light'
        },
        verificacion: {
          variant: 'info',
          type: 'light'
        },
        PrivacyPolicy: {
          variant: 'info',
          type: 'light'
        },
        citas: {
          variant: 'info',
          type: 'light'
        }
      }
    }
  },
  computed: {
    isRouteNameValid() {
      return ['ikco', 'saipa', 'veniran', 'censoautomotriz', 'verificacion', 'PrivacyPolicy', 'citas'].includes(this.$route.name)
    },
    isRouteNameDashboard() {
      return ['dashboard', 'VerificacionDashboard'].includes(this.$route.name)
    },
    mainImage() {
      return location.origin === ipsfa ? 'logoIpsfa' : 'mision-transporte'
    },
    routeImage() {
      switch (this.$route.name) {
        case 'ikco':
          return 'logoIkco'
        case 'saipa':
          return 'logoSaipa'
        case 'veniran':
          return 'logoVeniran'
        case 'citas':
          return 'logoAll'
        default:
          return false;
      }
    }
  }
}
</script>

<style lang="scss">
  input {
    background-color: white !important;
    &.form-control:disabled {
      background-color: #e9ecef !important;
    }
  }
  table {
    td[role=cell] { cursor: pointer; }
    p { margin: 0px; }
  }
  .logo {
    width: 150px;
    margin: 25px 0px 25px 0px;
  }
  .price {
    width: 350px;
    margin: 0px 0px 25px 25px;
  }
  .carPictures {
    width: 300px;
  }
  .vehicles-dropdown-list {
    align-items: center;
    p {
      text-indent: 1rem;
      margin: 0;
    }
    button {
      border: none;
      margin-left: .5rem;
      border-radius: 100%;
    }
  }
  *:required:invalid {
    border: 1px red solid;
  }
</style>
