import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  }, {
    path: '/ikco',
    name: 'ikco',
    component: () => import(/* webpackChunkName: "ikco" */ '../views/IkcoView.vue')
  }, {
    path: '/saipa',
    name: 'saipa',
    component: () => import(/* webpackChunkName: "saipa" */ '../views/SaipaView.vue')
  }, {
    path: '/veniran',
    name: 'veniran',
    component: () => import(/* webpackChunkName: "veniran" */ '../views/VeniranView.vue')
  }, {
    path: '/censoautomotriz',
    name: 'censoautomotriz',
    component: () => import(/* webpackChunkName: "censoautomotriz" */ '../views/CensoTransporte.vue')
  }, {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  }, {
    path: '/censoautomotriz/dashboard',
    name: 'CensusDashboard',
    component: () => import(/* webpackChunkName: "censoautomotriz-dashboard" */ '../views/CensusDashboardView.vue')
  }, {
    path: '/verificacion',
    name: 'verificacion',
    component: () => import(/* webpackChunkName: "verificacion" */ '../views/VerificationView.vue')
  }, {
    path: '/verificacion/dashboard',
    name: 'VerificacionDashboard',
    component: () => import(/* webpackChunkName: "verificacion-dashboard" */ '../views/VerificationDashboardView.vue')
  }, {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "verificacion-dashboard" */ '../views/PrivacyPolicyView.vue')
  }, {
    path: '/citas',
    name: 'citas',
    component: () => import(/* webpackChunkName: "verificacion-dashboard" */ '../views/AppointmentView.vue')
  }, {
    path: '/print/bill/:id',
    name: 'bill',
    component: () => import(/* webpackChunkName: "verificacion-dashboard" */ '../views/print/BillingView.vue')
  }, {
    path: '/print/prospect/:id',
    name: 'prospect',
    component: () => import(/* webpackChunkName: "verificacion-dashboard" */ '../views/print/ProspectView.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  setTimeout(() => {
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }, 1000);
})

export default router
